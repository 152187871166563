import { type LucideProps } from 'lucide-react';

const ChatIcon = (props: LucideProps) => (
    <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 7.24C3 6.08213 3 5.5032 3.23247 5.06414C3.42007 4.70983 3.70983 4.42007 4.06414 4.23247C4.5032 4 5.08213 4 6.24 4H17.5559C18.7138 4 19.2927 4 19.7318 4.23247C20.0861 4.42007 20.3759 4.70983 20.5635 5.06414C20.7959 5.5032 20.7959 6.08213 20.7959 7.24V13.2831C20.7959 14.4409 20.7959 15.0199 20.5635 15.4589C20.3759 15.8132 20.0861 16.103 19.7318 16.2906C19.2927 16.5231 18.7138 16.5231 17.5559 16.5231H8.94898C8.58856 16.5231 8.40835 16.5231 8.23562 16.5536C8.09332 16.5787 7.95813 16.6179 7.82445 16.6728C7.66218 16.7394 7.5081 16.8369 7.19996 17.032C5.78761 17.9261 3 19.6694 3 19.5V7.24Z" stroke="currentColor" />
        <path d="M8 9H15" stroke="currentColor" strokeLinecap="round" />
        <path d="M8 12H12" stroke="currentColor" strokeLinecap="round" />
    </svg>
);

export default ChatIcon;